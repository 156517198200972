import React, { useEffect } from 'react';
import Termine from '../components/Termine.js';
import HeaderBild from '../components/HeaderBild.js';
import { useDispatch } from 'react-redux';

import {
    Input,
    Textarea,
    SelectInput,
    CheckboxGroup,
    ButtonGroup, Button} from 'basic-components';

const TourForm = (props) => {
    const {
        state,
        handleSubmit,
        handleChange,
        isEditPage,
        dispatch,
        handleUpload,
        handleDelete,
    } = props;

    const reduxDispatch = useDispatch();


 const callToWordPress = async (urlToCompare) => {
    try {
        const response = await fetch('https://bikegenoss.ch/wp-json/wp/v2/guides?per_page=100');
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const tours = await response.json();

        const matchingTour = tours.find(tour => tour.link === urlToCompare);

        if (matchingTour) {
            console.log(`Found matching tour with ID: ${matchingTour.id}`);
            return matchingTour;
        } else {
            console.log('No matching tour found');
            return null; 
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return null; 
    }
};


    useEffect(() => {
        callToWordPress(state.link)
            .then(item => {
                if (item) {
                    console.log(item)
                    dispatch({
                        type: 'UPDATE_FIELD',
                        field: 'wp_id',
                        value: item.id,
                    });
                    dispatch({
                        type: 'UPDATE_FIELD',
                        field: 'name',
                        value: item.title.rendered,
                        });
                } else {
                    console.log('No tour found with the given URL.');
                }
        });    }, [state.link]);


    return (
        <form onSubmit={handleSubmit}>
            <Input
                type='text'
                name='name'
                value={state.name}
                onChange={handleChange}
                placeholder='Titel'
                label='Titel'
            />
            <Input
                type='text'
                name='wp_id'
                value={state.wp_id}
                onChange={handleChange}
                placeholder='WordPress ID'
                label='WordPress ID'
            />
            <Input
                type='text'
                name='link'
                value={state.link}
                onChange={handleChange}
                placeholder='Link'
                label='Link'
            />
            <ButtonGroup
                deleteItem={() => handleDelete(state)}
                isEditPage={isEditPage}
            />
        </form>
    );
};

export default TourForm;
