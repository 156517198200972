import React from 'react';
import Angebot from './containers/Organisator.tsx';

import ContentWrapper from '../../boilerplate/content-wrapper/ContentWrapper.tsx';

const NeueLeiterperson = props => {
    const { isEditPage, isImportPage } = props;

    return (
        <ContentWrapper
            title={isEditPage ? 'Organisator bearbeiten' : 'Organisator erfassen'}
            addButton={false}
            backButton={true}
        >
            <Angebot
                isEditPage={isEditPage}
                isImportPage={isImportPage}
            />
        </ContentWrapper>
    );
};

export default NeueLeiterperson;
