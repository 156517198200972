import React, { useEffect } from 'react';
import Termine from '../components/Termine.js';
import HeaderBild from '../components/HeaderBild.js';
import { useDispatch } from 'react-redux';

import Images from './Images.js';

import {
    Input,
    Textarea,
    SelectInput,
    CheckboxGroup,
    ButtonGroup,
    Button,
} from 'basic-components';
import {
    fetchTourFromSchweizMobil,
    fetchTourFromContentHub,
} from '../../../features/touren/tourenThunk.js';

const TourForm = props => {
    const {
        state,
        handleSubmit,
        handleChange,
        isEditPage,
        dispatch,
        handleUpload,
        handleDelete,
    } = props;

    const reduxDispatch = useDispatch();

    const handleImport = () => {
        if (state.route_id === '') return;
        reduxDispatch(fetchTourFromSchweizMobil(state.route_id));
    };

    const handleContentHubImport = () => {
        if (state.content_hub_id === '') return;
        reduxDispatch(fetchTourFromContentHub(state.content_hub_id));
    };

    const callToWordPress = async urlToCompare => {
        try {
            const response = await fetch(
                'https://bikegenoss.ch/wp-json/wp/v2/touren?per_page=100',
            );
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const tours = await response.json();

            const matchingTour = tours.find(tour => tour.link === urlToCompare);

            if (matchingTour) {
                console.log(`Found matching tour with ID: ${matchingTour.id}`);
                return matchingTour.id;
            } else {
                console.log('No matching tour found');
                return null;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    };

    useEffect(() => {
        callToWordPress(state.link).then(tourId => {
            if (tourId) {
                console.log(`The ID of the matching tour is: ${tourId}`);
                dispatch({
                    type: 'UPDATE_FIELD',
                    field: 'wp_id',
                    value: tourId,
                });
            } else {
                console.log('No tour found with the given URL.');
            }
        });
    }, [state.link]);

    const tags = [
        {
            name: 'SchweizMobil Tour',
            value: 'SchweizMobil Tour',
        },
        {
            name: 'ausgeschilderte Tour',
            value: 'ausgeschilderte Tour',
        },
        {
            name: 'E-Bike tauglich',
            value: 'E-Bike tauglich',
        },
        {
            name: 'Gravel tauglich',
            value: 'Gravel tauglich',
        },
        {
            name: 'Familienfreundlich',
            value: 'Familienfreundlich',
        },
        {
            name: 'Top',
            value: 'Top',
        },
    ];

    const regionen = [
        {
            text: 'Region auswählen',
            value: null,
            hidden: true,
        },
        {
            text: 'Nidwalden',
            value: 'Nidwalden',
        },
        {
            text: 'Obwalden',
            value: 'Obwalden',
        },
        {
            text: 'Luzern',
            value: 'Luzern',
        },
        {
            text: 'Uri',
            value: 'Uri',
        },
        {
            text: 'Schwyz',
            value: 'Schwyz',
        },
        {
            text: 'Engelberg',
            value: 'Engelberg',
        },
        {
            text: 'Entlebuch',
            value: 'Entlebuch',
        },
    ];

    return (
        <form onSubmit={handleSubmit}>
            <Input
                type='text'
                name='name'
                value={state.name}
                onChange={handleChange}
                placeholder='Titel'
                label='Titel'
            />
            <Input
                type='text'
                name='wp_id'
                value={state.wp_id}
                onChange={handleChange}
                placeholder='WordPress ID'
                label='WordPress ID'
            />
            <Input
                type='text'
                name='content_hub_id'
                value={state.content_hub_id}
                onChange={handleChange}
                placeholder='Content Hub ID'
                label='Content Hub ID'
            />
            <Input
                type='text'
                name='link'
                value={state.link}
                onChange={handleChange}
                placeholder='Link'
                label='Link'
            />
            <Input
                type='text'
                name='route_id'
                value={state.route_id}
                onChange={handleChange}
                placeholder='Route ID'
                label='Route ID'
            />
            <Textarea
                type='text'
                name='kurzbeschrieb'
                value={state.kurzbeschrieb}
                onChange={handleChange}
                placeholder='Kurzbeschreibung'
                label='Kurzbeschreibung'
            />
            <Textarea
                type='text'
                name='text'
                value={state.text}
                onChange={handleChange}
                placeholder='Text'
                label='Text'
            />
            <SelectInput
                options={regionen}
                label={'Region'}
                onChange={handleChange}
                name={'region'}
                value={state.region}
            />
            <CheckboxGroup
                options={tags}
                dispatch={dispatch}
                value={state.tags}
                name={'tags'}
            />
            <Images state={state} />
            <Button onClick={() => handleImport()}>Importieren</Button>
            <Button onClick={() => handleContentHubImport()}>
                von Content Hub importieren
            </Button>

            <ButtonGroup
                deleteItem={() => handleDelete(state)}
                isEditPage={isEditPage}
            />
        </form>
    );
};

export default TourForm;
