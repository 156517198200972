import React from 'react';
import Termine from '../components/Termine.js';
import HeaderBild from '../components/HeaderBild.js';

import {
    Input,
    Textarea,
    SelectInput,
    CheckboxGroup,
    ButtonGroup, Editor} from 'basic-components';


interface AngebotFormProps {
    state: {
        name: string;
        text: string;
        kurzbeschreibung: string;
        link: string;
    };
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    dispatch: (action: { type: string; field: string; value: string }) => void;
    isEditPage: boolean;
}

const regionen = [
    {
        text: 'Region auswählen',
        value: null,
        hidden: true,
    },
    {
        text: 'Nidwalden',
        value: 'Nidwalden',
    },
    {
        text: 'Obwalden',
        value: 'Obwalden',
    },
    {
        text: 'Luzern',
        value: 'Luzern',
    },
    {
        text: 'Uri',
        value: 'Uri',
    },
    {
        text: 'Schwyz',
        value: 'Schwyz',
    },
    {
        text: 'Engelberg',
        value: 'Engelberg',
    },
    {
        text: 'Entlebuch',
        value: 'Entlebuch',
    },
];

const tags = [
    {
        name: 'Kurs',
        value: 'Kurs',
    },
    {
        name: 'Tour',
        value: 'Tour',
    },
    {
        name: 'E-Bike tauglich',
        value: 'E-Bike tauglich',
    },
    {
        name: 'E-Bike spezifisch',
        value: 'E-Bike spezifisch',
    },
    {
        name: 'Kids',
        value: 'Kids',
    },
    {
        name: 'Women only',
        value: 'Women only',
    },
    {
        name: 'am Abend',
        value: 'am Abend',
    },
    {
        name: 'eintägig',
        value: 'eintägig',
    },
    {
        name: 'mehrtägig',
        value: 'mehrtägig',
    },
];
const AngebotForm = (props: AngebotFormProps) => {
    const {
        state,
        handleSubmit,
        handleChange,
        isEditPage,
        dispatch,
        handleUpload,
        handleDelete,
    } = props;
    return (
        <form onSubmit={handleSubmit}>
            <Input
                type='text'
                name='name'
                value={state.name}
                onChange={handleChange}
                placeholder='Titel'
                label='Titel'
            />
            <Textarea
                type='text'
                name='kurzbeschreibung'
                value={state.kurzbeschreibung}
                onChange={handleChange}
                placeholder='Kurzbeschreibung'
                label='Kurzbeschreibung'
            />
            <Editor
                type='text'
                name='text'
                value={state.text}
                onChange={handleChange}
                placeholder='Beschreibung'
                label='Beschreibung'
            />
            <Input
                type='text'
                name='preis'
                value={state.preis}
                onChange={handleChange}
                placeholder='Preis'
                label='Preis'
            />
 
            <Input
                type='text'
                name='link'
                value={state.link}
                onChange={handleChange}
                placeholder='Link'
                label='Link'
            />
            <SelectInput
                options={regionen}
                label={'Region'}
                onChange={handleChange}
                name={'region'}
                value={state.region}
            />
            <CheckboxGroup
                options={tags}
                dispatch={dispatch}
                value={state.tags}
                name={'tags'}
            />
            <Termine
                dispatch={dispatch}
                termine={state?.termine ? state.termine : []}
            />
            <HeaderBild
                dispatch={dispatch}
                headerbild={state?.headerbild?.id}
                handleUpload={handleUpload}
                imageImportUrl={state?.imageImportUrl}
                state={state}
            />
            <ButtonGroup
                deleteItem={() => handleDelete(state)}
                isEditPage={isEditPage}
            />
        </form>
    );
};

export default AngebotForm;
