import client from '../../helpers/directusClient';
import {
    readItems,
    readItem,
    updateItem,
    deleteItem,
    createItem,
    readFile,
    readAssetBlob,
    updateFile
} from '@directus/sdk';

import { iterativeImageCompression } from '../../pages/angebote/components/image-helper';

export const fetchTourenService = async () => {
    try {
        const response = await client.request(
            readItems('touren'),
        );




        return response;
    } catch (error) {
        console.error(`Error fetching item with ID:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID .`,
        );
    }
};

export const fetchTourService = async (angebotId) => {
    try {
        const response = await client.request(
            readItem('touren', angebotId, { fields: '*,galerie.*' }),
        );

        const blobPromises = response.galerie.map(async (item) => {
            const blob = await client.request(readAssetBlob(item.directus_files_id));
            item.blob = blob;
            return item;
        });

        response.galerie = await Promise.all(blobPromises);

        const imagePromises = response.galerie.map(async (item) => {
            const wp_id = await client.request(readFile(item.directus_files_id, { fields: 'wp_id' }));
            item.wp_id = wp_id?.wp_id;
            return item;
        });

        response.galerie = await Promise.all(imagePromises);


        return response;
    } catch (error) {
        console.error(`Error fetching item with ID ${angebotId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${angebotId}.`,
        );
    }
};


export const getTourFromSchweizMobilService = async (id) => {
    try {
        const response = await fetch(`https://schweizmobil.ch/api/4/route_or_segment/mountainbike/${id}/0?lang=de`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('There was a problem with your fetch operation:', error);
        throw error;
    }
};

export const getTourFromContentHubService = async (id) => {
    try {
        const response = await fetch(`https://meta.et4.de/rest.ashx/search/?experience=Bikegenoss&licensekey=BG67LUG82FHT3&type=Tour&template=ET2014A.json&q=id:${id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.items[0];
    } catch (error) {
        console.error('There was a problem with your fetch operation:', error);
        throw error;
    }
};

export const updateTourService = async (angebotId, angebot) => {
    try {
        const response = await client.request(
            updateItem('touren', angebotId, angebot),
        );
        return response;
    } catch (error) {
        console.error(`Error fetching item with ID ${angebotId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${angebotId}.`,
        );
    }
}

export const createTourService = async (angebot) => {
    try {
        const response = await client.request(
            createItem('touren', angebot),
        );
        return response;
    } catch (error) {
        console.error(`Error fetching item with ID`, error);
        throw new Error(
            `An error occurred while fetching the item with ID`,
        );
    }
}

export const deleteTourService = async (angebotId) => {
    try {
        const response = await client.request(
            deleteItem('touren', angebotId),
        );
    } catch (error) {
        console.error(`Error fetching item with ID ${angebotId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${angebotId}.`,
        );
    }
}

export const updateWordPressTour = async (tour) => {
    const id = tour.wp_id;

    const data = {
        title: tour.name,
        tags: tour?.tags?.split(','),
        galerie: tour?.galerie?.map((item) => {
            return parseInt(item.wp_id);
        })
    }
    try {
        const API_URL = process.env.REACT_APP_BIKEGENOSS_URL;
        const response = await fetch(`${API_URL}/wp-json/create-post/v2/touren/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('There was a problem with your fetch operation:', error);
    }
}

// Images

const getExtensionFromMimeType = (mimeType) => {
    const map = {
        'image/jpeg': '.jpg',
        'image/png': '.png',
        // add more mappings as needed
    };
    return map[mimeType] || '';
};

export const uploadImageToWordPress = async (fileObj) => {
    const fileName = fileObj.directus_files_id + getExtensionFromMimeType(fileObj.blob.type);
    const fileToCompress = new File([fileObj.blob], fileName, { type: fileObj.blob.type });

    const compressedFile = await iterativeImageCompression(fileToCompress);

    const data = new FormData();
    data.append('file', compressedFile, compressedFile.name);

    try {
        const response = await fetch(`${process.env.REACT_APP_BIKEGENOSS_URL}/wp-json/myplugin/v1/upload-image/`, {
            method: 'POST',
            body: data,
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error during image upload:', error);
        throw error;
    }
};



export const updateImageService = async (fileId, wp_id) => {
    try {
        const response = await client.request(
            updateFile(fileId, { wp_id: wp_id }),
        );
        return response;
    } catch (error) {
        console.error(`Error fetching item with ID ${fileId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${fileId}.`,
        );
    }
}