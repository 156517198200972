import { createSlice } from '@reduxjs/toolkit';

const angeboteSlice = createSlice({
    name: 'angebote',
    initialState: {
        data: null,
        tmpAngebot: null,
        loading: false,
        error: null,
    },
    reducers: {
        fetchAngeboteStart: (state) => {
            state.loading = true;
        },
        fetchAngeboteSuccess: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchAngebotSuccess: (state, action) => {
            state.tmpAngebot = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchAngeboteFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        updateAngeboteStart: (state) => {
            state.loading = true;
        },
        updateAngeboteSuccess: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        },
        updateAngeboteFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        resetAngebotAction: (state) => {
            state.tmpAngebot = null;
        }
    },
});

export const {
    fetchAngeboteStart,
    fetchAngeboteSuccess,
    fetchAngeboteFailure,
    updateAngeboteStart,
    updateAngeboteSuccess,
    updateAngeboteFailure,
    fetchAngebotSuccess,
    resetAngebotAction
} = angeboteSlice.actions;

export default angeboteSlice.reducer;
