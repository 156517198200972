import React, { useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { initialState, formReducer } from '../helpers.ts';
import AngebotForm from './TourForm.tsx';
import '../components/publish-box.scss';

import { useSelector, useDispatch } from 'react-redux';
import {
    createTour,
    updateTour,
    deleteTour,
    fetchTour,
} from '../../../features/touren/tourenThunk.js';
import { resetAngebotAction } from '../../../features/angebote/angeboteSlice.js';

import {
    Input,
    Textarea,
    SelectInput,
    CheckboxGroup,
    ButtonGroup,
    Button,
} from 'basic-components';
import ContentHubSearch from '../components/ContentHubSearch.js';

function Angebot(props) {
    const { isEditPage, isImportPage } = props;
    const navigate = useNavigate();
    let { id } = useParams();

    const reduxDispatch = useDispatch();

    useEffect(() => {
        if (!isEditPage) {
            reduxDispatch(resetAngebotAction());
        } else {
            reduxDispatch(fetchTour(id));
            console.log(id);
        }
    }, []);

    const data = useSelector(state => state.touren.tmpTour);
    const schweizMobilTour = useSelector(
        state => state.touren.schweizMobilTour,
    );

    useEffect(() => {
        console.log(data);
        if (isEditPage) {
            updateFormState(data);
        }
    }, [isImportPage, id, data]);

    useEffect(() => {
        dispatch({
            type: 'UPDATE_FIELD',
            field: 'name',
            value: schweizMobilTour?.title,
        });
        dispatch({
            type: 'UPDATE_FIELD',
            field: 'text',
            value: schweizMobilTour?.description,
        });
        dispatch({
            type: 'UPDATE_FIELD',
            field: 'kurzbeschrieb',
            value: schweizMobilTour?.abstract,
        });
    }, [schweizMobilTour]);

    const [state, dispatch] = useReducer(formReducer, initialState);

    const updateFormState = itemData => {
        if (!itemData) {
            return;
        }
        const fields = [
            'id',
            'name',
            'route_id',
            'wp_id',
            'content_hub_id',
            'text',
            'kurzbeschrieb',
            'tags',
            'galerie',
        ];

        fields.forEach(field => {
            dispatch({
                type: 'UPDATE_FIELD',
                field: field,
                value: itemData[field],
            });
        });
    };

    const handleChange = e => {
        dispatch({
            type: 'UPDATE_FIELD',
            field: e.target.name,
            value: e.target.value,
        });
    };

    const submit = e => {
        e.preventDefault();
        if (isEditPage) {
            reduxDispatch(updateTour(id, state));
        } else {
            reduxDispatch(createTour(state))
                .then(res => {
                    console.log(res);
                    navigate('/touren/bearbeiten/' + res.id);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    const handleDelete = state => {
        reduxDispatch(deleteTour(state.id));
        navigate('/angebote');
    };

    return (
        <div className={'base-container'}>
            <AngebotForm
                handleSubmit={submit}
                handleChange={handleChange}
                //handleUpload={handleUpload}
                state={state}
                isEditPage={isEditPage}
                dispatch={dispatch}
                handleDelete={handleDelete}
            />
            <ContentHubSearch dispatch={dispatch} />
        </div>
    );
}

Angebot.defaultProps = {
    isEditPage: false,
};

export default Angebot;
