import React from 'react';
import './message-box.scss';

import { useSelector } from 'react-redux';

const ErrorDisplay = () => {

    const { visible, status, message } = useSelector(state => state.message);

    return (
        visible && (
            <div className={`message-box-wrapper ${alert ? '' : 'inline'}`}>
                <div className={`message-box ${visible ? 'active' : ''} ${status === 'error' ? 'error' : ''}`}>
                    {message}
                </div>
            </div>

        )

    );
};


export default ErrorDisplay;
