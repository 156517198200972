import React, {useEffect} from 'react';
import ContentWrapper from '../../boilerplate/content-wrapper/ContentWrapper.tsx';

import { BasicTable } from '../../boilerplate/tables/BasicTable.tsx';
import { useQuery } from 'react-query';
import { queryMany, FetchResponse } from '../../helpers/functions.ts';

import { Angebot } from '../../types/angebot';

import { useDispatch, useSelector } from 'react-redux';
import { fetchTouren } from '../../features/touren/tourenThunk.js';
import { fetchGeneralCollection, fetchUsers } from '../../features/general-collection/generelCollectionThunk.js';

const Angebote = () => {

        const dispatch = useDispatch();

    const {data, loading, error} = useSelector((state) => state.generalCollection);

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);

    const columns = React.useMemo(
        () => [
            {
                name: 'Vorname',
                selector: row => row.first_name,
            },
                        {
                name: 'Nachname',
                selector: row => row.last_name,
            },
            {
                name: 'Email',
                selector: row => row.email,
            },
                      {
                name: 'Organisator',
                selector: row => row.organisator?.name,
            },
        ],
        [],
    );

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error?.message}</div>;

    return (
        <ContentWrapper title='User'>
            {!loading && !error && data && (
                <BasicTable
                    data={data}
                    columns={columns}
                />
            )}
        </ContentWrapper>
    );
};

export default Angebote;
