// src/features/auth/authThunks.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from './services/authService.js'; // Your existing authService
import { setUser, setError, setLoading, setIsAuthenticated } from './authSlice.ts'; // Your existing authSlice

export interface LoginPayload {
    email: string;
    password: string;
}

export const loginUser = createAsyncThunk(
    'auth/login',
    async (
        { email, password }: LoginPayload,
        { dispatch, rejectWithValue },
    ) => {
        dispatch(setLoading(true));
        try {
            const isAuthenticated = await authService.login(email, password);
            dispatch(setIsAuthenticated(isAuthenticated));
            const user = await authService.fetchCurrentUser();
            dispatch(setUser(user));
            dispatch(setError(null));
            return isAuthenticated;
        } catch (error) {
            dispatch(setError(error.errors[0].message || 'Failed to login '));
            return rejectWithValue(error);
        }
    },
);

export const refreshToken = createAsyncThunk(
    'auth/refreshToken',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const isAuthenticated = await authService.refresh();
            dispatch(setIsAuthenticated(isAuthenticated));
            const user = await authService.fetchCurrentUser();
            dispatch(setUser(user));
            return isAuthenticated;
        } catch (error) {
            dispatch(
                setError(
                    error.response?.data?.message || 'Failed to refresh token',
                ),
            );
            return rejectWithValue(error);
        }
    },
);

export const logout = createAsyncThunk(
    'auth/logout',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            await authService.logout();
            dispatch(setIsAuthenticated(null));
            dispatch(setUser(null));
            return;
        } catch (error) {
            dispatch(
                setError(error.response?.data?.message || 'Failed to logout'),
            );
            return rejectWithValue(error);
        }
    },
);
