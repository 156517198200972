import React from 'react';
import { NavLink } from 'react-router-dom';

interface NavItemProps {
	name: string;
	permalink: string;
}

const NavItem: React.FC<NavItemProps> = ({ name, permalink }) => {
	return (
		<li className='list-item'>
			<NavLink
				className={({ isActive }) => (isActive ? 'is-active' : undefined)}
				to={permalink}
			>
				{name}
			</NavLink>
		</li>
	);
};

export default NavItem;
