import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken } from './features/auth/authThunks.ts';

import { RootState } from './store.ts';
import { AppDispatch } from './store';

import Navigation from './boilerplate/navigation/Navigation.tsx';

import {
    Login,
    Angebote,
    NeuesAngebot,
    Touren,
    NeueTour,
    Organisatoren,
    NeuerOrganisator,
    User,
    NeuerUser,
} from './pages';

import './app.scss';
import { ProtectedRoute } from './boilerplate/protected-route/ProtectedRoute.tsx';
import NavLogo from './components/nav-logo/NavLogo.tsx';
const navigationConfig: Record<string, NavItemConfig[]> = {
    admin: [
        { name: 'Angebote', link: '/angebote' },
        { name: 'Organisatoren', link: '/organisatoren' },
        { name: 'User', link: '/user' },
        { name: 'Touren', link: '/touren' },
    ],
    basic: [{ name: 'Angebote', link: '/angebote' }],
    settings: [{ name: 'Einstellungen', link: '/' }],
};

function App() {
    const dispatch = useDispatch<AppDispatch>();
    const isAuthenticated = useSelector(
        (state: RootState) => state.auth.isAuthenticated,
    );

    const user = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
        console.log(user);
    }, [user]);

    useEffect(() => {
        dispatch(refreshToken()).unwrap();
    }, [dispatch]);

    const pages = [
        {
            name: 'Angebote',
            link: '/angebote',
            component: <Angebote />,
        },
        {
            name: 'Organisatoren',
            link: '/organisatoren',
            component: <Organisatoren />,
        },
        {
            name: 'Neuer Organisator',
            link: '/organisatoren/neu',
            component: <NeuerOrganisator />,
        },
        {
            name: 'Organisator bearbeiten',
            link: '/organisatoren/bearbeiten/:id',
            component: <NeuerOrganisator isEditPage={true} />,
        },

        {
            name: 'User',
            link: '/user',
            component: <User />,
        },
        {
            name: 'Neuer User',
            link: '/user/neu',
            component: <NeuerUser />,
        },
        {
            name: 'User bearbeiten',
            link: '/user/bearbeiten/:id',
            component: <NeuerUser isEditPage={true} />,
        },
        {
            name: 'Touren',
            link: '/touren',
            component: <Touren />,
        },
        {
            name: 'Neue Tour',
            link: '/touren/neu',
            component: <NeueTour />,
        },
        {
            name: 'Tour bearbeiten',
            link: '/touren/bearbeiten/:id',
            component: <NeueTour isEditPage={true} />,
        },
        {
            name: 'Neues Angebot',
            link: '/angebote/neu',
            component: <NeuesAngebot />,
        },
        {
            name: 'Angebot importieren',
            link: '/angebote/importieren/:id',
            component: (
                <NeuesAngebot
                    isEditPage={false}
                    isImportPage={true}
                />
            ),
        },
        {
            name: 'Angebot Bearbeiten',
            link: '/angebote/bearbeiten/:id',
            component: <NeuesAngebot isEditPage={true} />,
        },

        {
            name: 'Angebote',
            link: '/dashboard',
            component: <Navigate to='/angebote' />,
        },
        {
            name: 'Angebote',
            link: '/',
            component: <Navigate to='/angebote' />,
        },
    ];

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path='login'
                    element={<Login />}
                />
                <Route
                    path='/'
                    element={
                        <Navigation
                            NavLogo={NavLogo}
                            navigationConfig={navigationConfig}
                            user={user}
                        />
                    }
                >
                    {pages.map(page => (
                        <Route
                            key={page.name}
                            path={page.link}
                            element={
                                <ProtectedRoute>
                                    {page.component}
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
