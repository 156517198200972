
import { setMessageAction, setErrorAction, setSuccessAction, resetAction } from './messageSlice';
export const showSuccessMessage = (message) => (dispatch) => {
    dispatch(setSuccessAction(message));
    setTimeout(() => {
        dispatch(resetAction());
    }, 5000);
};

export const showErrorMessage = (message) => (dispatch) => {
    dispatch(setErrorAction(message));
    setTimeout(() => {
        dispatch(resetAction());
    }, 5000);
}