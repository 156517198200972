import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './images.scss';

import client from '../../../helpers/directusClient';
import { importFile } from '@directus/sdk';

import { Button } from 'basic-components';

import { useDispatch } from 'react-redux';
import { updateTour, uploadImagesToWordpress, fetchTour } from '../../../features/touren/tourenThunk';

export default function Images(props) {
    const { state } = props;
    const data = useSelector(state => state.touren.contentHubTour);
    const dispatch = useDispatch();

    const [selectedImages, setSelectedImages] = useState({});

    const handleSelectImage = (url, isSelected) => {
        setSelectedImages(prev => ({
            ...prev,
            [url]: isSelected,
        }));
    };

    const createGalerieObject = (ids) => {

        const obj = {
            "update": [],
            "delete": [],
            "create": []
        }

        for (const id of ids) {
            obj.create.push({
                "touren_id": state.id,
                "directus_files_id": {
                    "id": id
                }
            });
        }

        return obj;

    }

    const handleImport = async () => {
        const importedImageIds = [];


        for (const url of Object.keys(selectedImages)) {
            if (selectedImages[url]) {
                let type = 'image/jpeg';
                if (url.includes('.png')) {
                    type = 'image/png';
                }
                const result = await client.request(importFile(url, { title: state.name, type: type }));

                if (result && result.id) {
                    importedImageIds.push(result.id);
                }
            }
        }


        dispatch(updateTour(state.id, { galerie: createGalerieObject(importedImageIds) }))
    };

    const syncWithWordPress = async () => {
        dispatch(uploadImagesToWordpress(state.galerie)).then((response) => {
            dispatch(fetchTour(state.id));
        })
            .catch((error) => {
                console.error('Error fetching from content hub:', error);
            });
    }

    return (
        <div className="image-container">
            <div className="gallery">
                {state.galerie && state.galerie.map((item, index) => {
                    const imageUrl = URL.createObjectURL(item.blob);


                    return <div><img key={index} src={imageUrl} alt={`Gallery item ${index + 1}`} onLoad={() => URL.revokeObjectURL(imageUrl)} /></div>;
                })}
                <Button onClick={syncWithWordPress}>Upload Images to WordPress</Button>

            </div>
            {
                data?.media_objects?.map((image, index) => (
                    <div key={index} className="image-row">
                        <div className="image-img">
                            <img src={image.url} alt="Imported Media" />
                        </div>
                        <div className="image-content">
                            <input
                                type="checkbox"
                                checked={!!selectedImages[image.url]}
                                onChange={(e) => handleSelectImage(image.url, e.target.checked)}
                            />
                            <span> Import this image</span>
                        </div>
                    </div>
                ))
            }
            <Button onClick={handleImport}>Import Selected Images</Button>
        </div>
    );
}
