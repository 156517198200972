import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import './navigation.scss';
import { NavItem } from './components';

interface NavItemConfig {
    name: string;
    link: string;
}

const Navigation = ({ NavLogo, navigationConfig, user }) => {


    const renderNavItems = (navigationConfig, role) => {
        if (role?.name === 'Administrator') {
            return navigationConfig.admin.map(item => (
                <NavItem
                    key={item.name}
                    name={item.name}
                    permalink={item.link}
                />
            ));
        } else {
            return navigationConfig.basic.map(item => (
                <NavItem
                    key={item.name}
                    name={item.name}
                    permalink={item.link}
                />
            ));
    
        }
    }

    return (
        <div className='page-wrapper'>
            <div className='page-wrapper--sidebar'>
                <div>
                    <NavLogo />
                    <nav>
                        <ul className='main-nav'>
                            {renderNavItems(navigationConfig, user?.role)}
                        </ul>
                        <ul className='sub-nav'>
                            {navigationConfig.settings.map(item => (
                                <NavItem
                                    key={item.name}
                                    name={item.name}
                                    permalink={item.link}
                                />
                            ))}
                        </ul>
                    </nav>
                </div>

                <div className='sidebar-bottom'>
                    <div className='user-teaser'>
                        <span className='user-teaser__short'>{user?.first_name?.charAt(0).toUpperCase()}</span>
                        <Link
                            to={'/login'}
                            className='user-teaser__name'
                        >
                            abmelden
                        </Link>
                    </div>
                </div>
            </div>

            <div className='page-wrapper--main'>
                <Outlet />
                <Link
                    to='/version'
                    className='page-wrapper--version'
                >
                    Version {process.env.REACT_APP_VERSION || '0.9.1'}
                </Link>
            </div>
        </div>
    );
};

export default Navigation;
