import React from 'react';

const transformAnmeldung = anmeldung => {
    const tmpAnmeldung = { ...anmeldung, ...JSON.parse(anmeldung.fields) };
    const allSlotsNotNull = anmeldung.workshops.every(
        workshop => workshop.slot !== null,
    );

    if (allSlotsNotNull) {
        tmpAnmeldung.zuteilung = 'zugeteilt';
    } else {
        tmpAnmeldung.zuteilung = 'nicht zugeteilt';
    }
    return tmpAnmeldung;
};

const tableColumns = [
    {
        name: 'Schule',
        key: 'schule',
    },
    {
        name: 'Status',
        key: 'status',
    },
    {
        name: 'Zuteilung',
        key: 'zuteilung',
    },
];

const statusCellStyle = row => {
    return {
        color: row.status !== 'open' ? '#069C56' : '#FF681E',
        borderRadius: 5,
        border: '2px solid currentColor',
        paddingLeft: '4px',
        paddingRight: '4px',
        lineHeight: 1.5,
        fontWeight: 500,
    };
};

const zuteilungCellStyle = row => {
    return {
        color: row.zuteilung !== 'nicht zugeteilt' ? '#069C56' : '#FF681E',
        borderRadius: 5,
        border: '2px solid currentColor',
        paddingLeft: '4px',
        paddingRight: '4px',
        lineHeight: 1.5,
        fontWeight: 500,
    };
};

const convertStatusToText = status => {
    if (status === 'open') {
        return 'offen';
    } else if (status === 'confirmed') {
        return 'bestätigt';
    } else {
        return status;
    }
};

const anmeldungTableColums = tableColumns => {
    return tableColumns.map(column => {
        if (column.key === 'status') {
            return {
                name: column.name,
                selector: row => row[column.key],
                sortable: true,
                cell: row => (
                    <div style={statusCellStyle(row)}>
                        {convertStatusToText(row.status)}
                    </div>
                ),
            };
        } else if (column.key === 'zuteilung') {
            return {
                name: column.name,
                selector: row => row[column.key],
                sortable: true,
                cell: row => (
                    <div style={zuteilungCellStyle(row)}>
                        {convertStatusToText(row.zuteilung)}
                    </div>
                ),
            };
        } else {
            return {
                name: column.name,
                selector: row => row[column.key],
                sortable: true,
            };
        }
    });
};

export {
    transformAnmeldung,
    tableColumns,
    statusCellStyle,
    zuteilungCellStyle,
    convertStatusToText,
    anmeldungTableColums,
};
