import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import Spinner from '../spinner/Spinner.tsx';

export const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const { isAuthenticated, loading } = useSelector(state => state.auth);

    if (loading) {
        return <Spinner />;
    }

    if (!isAuthenticated) {
        return (
            <Navigate
                to='/login'
                state={{ from: location }}
                replace
            />
        );
    }

    return children;
};
