import React, { useEffect, useState } from 'react';
import './header-bild.scss';
import { changeFileExtensionIfNeeded, iterativeImageCompression } from './image-helper';
import { uploadFiles, readAssetRaw } from '@directus/sdk';


import client from '../../../helpers/directusClient';

export default function HeaderBild(props) {
    const [imageUrl, setImageUrl] = useState(null);
    const { dispatch, headerbild, handleUpload, imageImportUrl, state } = props;

    const onFileInputChange = async (e) => {

        const file = e.target.files[0];

        if (file && file.type.startsWith('image/')) {

            const modifiedFile = changeFileExtensionIfNeeded(file);
            try {
                const compressedFile = await iterativeImageCompression(modifiedFile);

                const fileUrl = URL.createObjectURL(compressedFile);
                setImageUrl(fileUrl);

                uploadImage(compressedFile);
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    async function addImageFromUrl(url, upload = false) {
        try {
            const response = await fetch(url);
            const blob = await response.blob();

            const detailsUrl = url.replace('assets', 'files');
            const detailsResponse = await fetch(detailsUrl);
            const detailsData = await detailsResponse.json();
            const details = detailsData.data;

            const fileName = details.filename_download;
            const fileType = details.type;

            const file = new File([blob], fileName, { type: fileType });

            const modifiedFile = changeFileExtensionIfNeeded(file);
            const compressedFile = await iterativeImageCompression(modifiedFile);

            const fileUrl = URL.createObjectURL(compressedFile);
            setImageUrl(fileUrl);

            if (upload) {
                uploadImage(compressedFile);
            }

        } catch (error) {
            console.error('Error loading image from URL:', error);
        }
    }


    useEffect(() => {
        if (imageImportUrl === undefined) return;

        addImageFromUrl(imageImportUrl);

    }, [imageImportUrl]);


    async function uploadImage(file) {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const result = await client.request(uploadFiles(formData));

            dispatch({
                type: 'UPDATE_FIELD',
                field: 'headerbild',
                value: result.id,
            });
            handleUpload({ ...state, headerbild: result.id });



        } catch (error) {
            console.error('Error uploading image:', error);
        }

    }

    const getThumbnailUrl = async (id) => {
        const result = await client.request(readAssetRaw(id));
        streamToImage(result);
    }

    const streamToImage = (imageStream) => {
        const reader = imageStream.getReader();
        const chunks = [];

        reader.read().then(function processText({ done, value }) {
            if (done) {
                const blob = new Blob(chunks, { type: 'image/jpeg' });
                const url = URL.createObjectURL(blob);
                setImageUrl(url);
                return;
            }
            chunks.push(value);
            return reader.read().then(processText);
        });
    }


    useEffect(() => {
        if (headerbild) {
            getThumbnailUrl(headerbild);

        }
    }, [headerbild]);

    return (
        <div className="add-new">

            <div>
                <div className="file-input">
                    <input
                        type="file"
                        id="headerbild"
                        className="file"
                        onChange={onFileInputChange}
                        key="headerbild"
                    />
                    <label htmlFor="headerbild">{!imageUrl ? 'Headerbild hochladen' : 'Headerbild bearbeiten'}</label>
                    <div className="preview">

                        {imageUrl && <img src={imageUrl} alt="Header Preview" />}

                    </div>
                </div>

            </div>
            {/* 
            <button type="button" onClick={() => addImageFromUrl(imageImportUrl, true)}> Upload</button >*/}
        </div >
    );
}
