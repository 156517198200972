import { createSlice } from '@reduxjs/toolkit';

const messageSlice = createSlice({
    name: 'error',
    initialState: {
        message: null,
        status: null,
        visible: false,
    },
    reducers: {
        setSuccessAction: (state, action) => {
            state.message = action.payload;
            state.status = 'success';
            state.visible = true;

        },
        setErrorAction: (state, action) => {
            state.message = action.payload;
            state.status = 'error';
            state.visible = true;

        },
        resetAction: (state) => {
            state.message = null
            state.status = null;
            state.visible = false;
        }

    },
});

export const {
    setSuccessAction,
    setErrorAction,
    resetAction
} = messageSlice.actions;

export default messageSlice.reducer;
