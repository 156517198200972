import React, { useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { formReducer } from '../helpers.ts';
import AngebotForm from './UserForm.tsx';


import { useSelector, useDispatch } from 'react-redux';
import { fetchGeneralItem } from '../../../features/general-collection/generelCollectionThunk.js';
import { resetGeneralCollectionAction } from '../../../features/general-collection/generalCollectionSlice.js';
import { updateUser, createUser, deleteUser, fetchSingleUser } from '../../../features/general-collection/generelCollectionThunk.js';

const initialState = {
    first_name: '',
    last_name: '',
    email: '',
}

function Angebot(props) {
    const { isEditPage, isImportPage } = props;
    const navigate = useNavigate();
    let { id } = useParams();

    const reduxDispatch = useDispatch();

    useEffect(() => {
        if (!isEditPage) {            
            reduxDispatch(resetGeneralCollectionAction());
        } else {
            reduxDispatch(fetchSingleUser(id));
            console.log(id)
        }
    }, []);



    const data = useSelector((state) => state.generalCollection.item);
    const schweizMobilTour = useSelector((state) => state.touren.schweizMobilTour);
    
    useEffect(() => {
        console.log(data)
        if (isEditPage) {
            updateFormState(data);
        }
    }, [isImportPage, id, data]);
    


    useEffect(() => {
        dispatch({
            type: 'UPDATE_FIELD',
            field: 'name',
            value: schweizMobilTour?.title,
        });
    }, [schweizMobilTour]);

    const [state, dispatch] = useReducer(formReducer, initialState);

    const updateFormState = itemData => {
        if (!itemData) {
            return;
        }
        const fields = [
            'id',
            'first_name',
            'last_name',
            'email',
            'link',
            'organisator'
        ];

        fields.forEach(field => {
         
                dispatch({
                    type: 'UPDATE_FIELD',
                    field: field,
                    value: itemData[field],
                });
            
        });
    };



    const handleChange = e => {
        dispatch({
            type: 'UPDATE_FIELD',
            field: e.target.name,
            value: e.target.value,
        });
    };

    const submit = e => {
        e.preventDefault();
        if (isEditPage) {
            delete state.role;
            reduxDispatch(updateUser(id, state))
        } else {
            reduxDispatch(createUser(state)).then((res) => {
                console.log(res)
                navigate('/user/bearbeiten/' + res.id)
            }
            ).catch((err) => {
                console.log(err)
            })
        }
    };


    const handleDelete = (state) => {
        reduxDispatch(deleteUser(id));
        navigate('/user');
    }

    return (
        <div className={'base-container'}>
            <AngebotForm
                handleSubmit={submit}
                handleChange={handleChange}
                //handleUpload={handleUpload}
                state={state}
                isEditPage={isEditPage}
                dispatch={dispatch}
                handleDelete={handleDelete}
            />

        
        </div>
    );
}

Angebot.defaultProps = {
    isEditPage: false,
};

export default Angebot;
