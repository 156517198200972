import { createSlice } from '@reduxjs/toolkit';

const tourenSlice = createSlice({
    name: 'touren',
    initialState: {
        data: null,
        schweizMobilTour: null,
        contentHubTour: null,
        tmpTour: null,
        loading: false,
        error: null,
    },
    reducers: {
        fetchTourenStart: (state) => {
            state.loading = true;
        },
        fetchTourenSuccess: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchTourSuccess: (state, action) => {
            state.tmpTour = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchSchweizMobilTourSuccess: (state, action) => {
            state.schweizMobilTour = action.payload;
            let tmpTags = state.tmpTour.tags;
            if (!tmpTags.includes('SchweizMobil Tour')) {
                tmpTags += ',SchweizMobil Tour';
            }
            state.tmpTour.tags = tmpTags;

        },
        fetchContentHubTourSuccess: (state, action) => {
            state.contentHubTour = action.payload;
        },
        fetchTourenFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchTourenStart,
    fetchTourenSuccess,
    fetchTourenFailure,
    fetchSchweizMobilTourSuccess,
    fetchTourSuccess,
    fetchContentHubTourSuccess

} = tourenSlice.actions;

export default tourenSlice.reducer;
