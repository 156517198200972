import React, {useEffect} from 'react';
import ContentWrapper from '../../boilerplate/content-wrapper/ContentWrapper.tsx';

import { BasicTable } from '../../boilerplate/tables/BasicTable.tsx';
import { useQuery } from 'react-query';
import { queryMany, FetchResponse } from '../../helpers/functions.ts';

import { Angebot } from '../../types/angebot';

import { useDispatch, useSelector } from 'react-redux';
import { fetchAngebote } from '../../features/angebote/angeboteThunk.js';


const Angebote = () => {

        const dispatch = useDispatch();

    const {data, loading, error} = useSelector((state) => state.angebote);

    useEffect(() => {
        dispatch(fetchAngebote());
    }, [dispatch]);

    const columns = React.useMemo(
        () => [
            {
                name: 'Name',
                selector: row => row.name,
            },
            {
                name: 'Region',
                selector: row => row.region,
            },
            {
                name: 'Organisator',
                selector: row => row.organisator?.name}
        ],
        [],
    );

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error?.message}</div>;

    return (
        <ContentWrapper title='Angebote'>
            {!loading && !error && data && (
                <BasicTable
                    data={data}
                    columns={columns}
                />
            )}
        </ContentWrapper>
    );
};

export default Angebote;
