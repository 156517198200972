import {
    fetchTourenStart,
    fetchTourenSuccess,
    fetchTourenFailure,
    fetchSchweizMobilTourSuccess,
    fetchTourSuccess,
    fetchContentHubTourSuccess
} from './tourenSlice';
import {
    createTourService,
    deleteTourService,
    fetchTourenService,
    getTourFromSchweizMobilService,
    updateTourService,
    fetchTourService,
    checkIfImageExists,
    getTourFromContentHubService,
    uploadImageToWordPress,
    updateImageService,
    updateWordPressTour
} from './tourenService';
import { showErrorMessage, showSuccessMessage } from '../message/messageThunk';

export const fetchTouren = () => async (dispatch) => {
    dispatch(fetchTourenStart());

    try {
        const data = await fetchTourenService();
        dispatch(fetchTourenSuccess(data));
    } catch (error) {
        dispatch(fetchTourenFailure(error.message));
    }
};

export const fetchTour = (angebotId) => async (dispatch) => {
    dispatch(fetchTourenStart());

    try {
        const data = await fetchTourService(angebotId);
        dispatch(fetchTourSuccess(data));
    } catch (error) {
        dispatch(fetchTourenFailure(error.message));
    }
};


export const fetchTourFromSchweizMobil = (id) => async (dispatch) => {
    try {
        const data = await getTourFromSchweizMobilService(id);
        dispatch(fetchSchweizMobilTourSuccess(data));
        dispatch(showSuccessMessage('Tour fetched from SchweizMobil'));
    } catch (error) {
        dispatch(showErrorMessage('Error fetching tour from SchweizMobil'));
    }
}

export const fetchTourFromContentHub = (id) => async (dispatch) => {
    try {
        const data = await getTourFromContentHubService(id);
        console.log(data);
        dispatch(fetchContentHubTourSuccess(data));
        dispatch(showSuccessMessage('Tour fetched from ContentHub'));
    } catch (error) {
        dispatch(showErrorMessage('Error fetching tour from SchweizMobil'));
    }
}


export const updateTour = (angebotId, angebot) => async (dispatch) => {
    dispatch(fetchTourenStart());
    try {
        const response = await updateTourService(angebotId, angebot);
        dispatch(showSuccessMessage('Angebot wurde erfolgreich gespeichert'));
        dispatch(fetchTourenSuccess(response));

        const data = await fetchTourService(angebotId);
        dispatch(fetchTourSuccess(data));

        const response2 = updateWordPressTour(data);


    } catch (error) {
        dispatch(fetchTourenFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gespeichert werden'));
    }
}

export const createTour = (angebot) => async (dispatch) => {
    dispatch(fetchTourenStart());
    try {
        const response = await createTourService(angebot);
        dispatch(fetchTourenSuccess(response));
        dispatch(showSuccessMessage('Angebot wurde erfolgreich gespeichert'));
        return response;
    } catch (error) {
        dispatch(fetchTourenFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gespeichert werden'));
    }
}

export const deleteTour = (angebotId) => async (dispatch) => {
    dispatch(fetchTourenStart());
    try {
        deleteTourService(angebotId).then(() => {
            dispatch(fetchTourenService()).then((data) => {
                dispatch(showSuccessMessage('Angebot wurde erfolgreich gelöscht'));

            })
        }

        )

    } catch (error) {
        dispatch(fetchTourenFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gelöscht werden'));
    }
}

export const uploadImagesToWordpress = (galerie) => async (dispatch) => {
    try {
        const uploadPromises = galerie.map(async (file) => {
            const uploadResponse = await uploadImageToWordPress(file);

            await updateImageService(file.directus_files_id, uploadResponse.id);

            return uploadResponse;
        });

        const responses = await Promise.all(uploadPromises);

        dispatch(showSuccessMessage('All images uploaded to WordPress successfully'));
    } catch (error) {
        console.error('Error uploading images to WordPress', error);
        dispatch(showErrorMessage('Error uploading images to WordPress'));
    }
};


