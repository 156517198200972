import { createSlice } from '@reduxjs/toolkit';

const generalCollectionSlice = createSlice({
    name: 'touren',
    initialState: {
        data: null,
        item: null,
        loading: false,
        error: null,
    },
    reducers: {
        fetchGeneralCollectionStart: (state) => {
            state.loading = true;
        },
        fetchGeneralCollectionSuccess: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchGeneralItemSuccess: (state, action) => {
            state.item = action.payload;
            state.loading = false;
            state.error = null;
        },
        fetchGeneralCollectionFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        resetGeneralCollectionAction: (state) => {
            state.item = null;
        }
    },
});

export const {
    fetchGeneralCollectionStart,
    fetchGeneralCollectionSuccess,
    fetchGeneralItemSuccess,
    fetchGeneralCollectionFailure,
    resetGeneralCollectionAction

} = generalCollectionSlice.actions;

export default generalCollectionSlice.reducer;
