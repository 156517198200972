import React, {useEffect} from 'react';
import ContentWrapper from '../../boilerplate/content-wrapper/ContentWrapper.tsx';

import { BasicTable } from '../../boilerplate/tables/BasicTable.tsx';
import { useQuery } from 'react-query';
import { queryMany, FetchResponse } from '../../helpers/functions.ts';

import { Angebot } from '../../types/angebot';

import { useDispatch, useSelector } from 'react-redux';
import { fetchTouren } from '../../features/touren/tourenThunk.js';
import { fetchGeneralCollection } from '../../features/general-collection/generelCollectionThunk.js';

const Angebote = () => {

        const dispatch = useDispatch();

    const {data, loading, error} = useSelector((state) => state.generalCollection);

    useEffect(() => {
        dispatch(fetchGeneralCollection('organisatoren'));
    }, [dispatch]);

    const columns = React.useMemo(
        () => [
            {
                name: 'Name',
                selector: row => row.name,
            },
                        {
                name: 'Link',
                selector: row => row.link,
            },
            {
                name: 'WordPress ID',
                selector: row => row.wp_id,
            },
        ],
        [],
    );

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error?.message}</div>;

    return (
        <ContentWrapper title='Organisationen'>
            {!loading && !error && data && (
                <BasicTable
                    data={data}
                    columns={columns}
                />
            )}
        </ContentWrapper>
    );
};

export default Angebote;
