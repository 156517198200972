import React from 'react';
import './logo.scss';

const NavLogo: React.FC = () => {
    return (
        <div className='logo-wrapper'>
            <img
                src='/assets/bikegenoss_ozs_pos_rgb.svg'
                alt='logo'
            />
        </div>
    );
};

export default NavLogo;
