
import {
    fetchGeneralCollectionService,
    fetchGeneralItemService,
    updateGeneralItemService,
    createGeneralItemService,
    deleteGeneralItemService,
    fetchUsersService,
    fetchSingleUserService,
    deleteUserService,
    createUserService,
    updateUserService
} from './generalCollectionService';
import { showErrorMessage, showSuccessMessage } from '../message/messageThunk';
import { fetchGeneralCollectionFailure, fetchGeneralCollectionStart, fetchGeneralCollectionSuccess, fetchGeneralItemSuccess } from './generalCollectionSlice';

export const fetchGeneralCollection = (collection) => async (dispatch) => {
    dispatch(fetchGeneralCollectionStart());

    try {
        const data = await fetchGeneralCollectionService(collection);
        dispatch(fetchGeneralCollectionSuccess(data));
    } catch (error) {
        dispatch(fetchGeneralCollectionFailure(error.message));
    }
};

export const fetchGeneralItem = (collection, itemId) => async (dispatch) => {
    dispatch(fetchGeneralCollectionStart());

    try {
        const data = await fetchGeneralItemService(collection, itemId);
        dispatch(fetchGeneralItemSuccess(data));
    } catch (error) {
        dispatch(fetchGeneralCollectionFailure(error.message));
    }
};


export const updateGeneralItem = (collection, angebotId, angebot) => async (dispatch) => {
    dispatch(fetchGeneralCollectionStart());
    try {
        const response = await updateGeneralItemService(collection, angebotId, angebot);
        dispatch(showSuccessMessage('Angebot wurde erfolgreich gespeichert'));
        dispatch(fetchGeneralCollectionSuccess(response));

    } catch (error) {
        dispatch(fetchGeneralCollectionFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gespeichert werden'));
    }
}

export const createGeneralItem = (collection, angebot) => async (dispatch) => {
    dispatch(fetchGeneralCollectionStart());
    try {
        const response = await createGeneralItemService(collection, angebot);
        dispatch(fetchGeneralCollectionSuccess(response));
        dispatch(showSuccessMessage('Angebot wurde erfolgreich gespeichert'));
        return response;
    } catch (error) {
        dispatch(fetchGeneralCollectionFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gespeichert werden'));
    }
}

export const deleteGeneralItem = (collection, angebotId) => async (dispatch) => {
    dispatch(fetchGeneralCollectionStart());
    try {
        deleteGeneralItemService(collection, angebotId).then(() => {
            dispatch(fetchGeneralCollectionService()).then((data) => {
                dispatch(showSuccessMessage('Angebot wurde erfolgreich gelöscht'));

            })
        }

        )

    } catch (error) {
        dispatch(fetchGeneralCollectionFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gelöscht werden'));
    }
}

export const fetchUsers = () => async (dispatch) => {
    dispatch(fetchGeneralCollectionStart());

    try {
        const data = await fetchUsersService();
        dispatch(fetchGeneralCollectionSuccess(data));
    } catch (error) {
        dispatch(fetchGeneralCollectionFailure(error.message));
    }
};

export const fetchSingleUser = (itemId) => async (dispatch) => {
    dispatch(fetchGeneralCollectionStart());

    try {
        const data = await fetchSingleUserService(itemId);
        dispatch(fetchGeneralItemSuccess(data));
    } catch (error) {
        dispatch(fetchGeneralCollectionFailure(error.message));
    }
};
export const updateUser = (angebotId, angebot) => async (dispatch) => {
    dispatch(fetchGeneralCollectionStart());
    try {
        const response = await updateUserService(angebotId, angebot);
        dispatch(showSuccessMessage('Angebot wurde erfolgreich gespeichert'));
        dispatch(fetchGeneralCollectionSuccess(response));

    } catch (error) {
        dispatch(fetchGeneralCollectionFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gespeichert werden'));
    }
}

export const createUser = (angebot) => async (dispatch) => {
    dispatch(fetchGeneralCollectionStart());
    try {
        const response = await createUserService(angebot);
        dispatch(fetchGeneralCollectionSuccess(response));
        dispatch(showSuccessMessage('Angebot wurde erfolgreich gespeichert'));
        return response;
    } catch (error) {
        dispatch(fetchGeneralCollectionFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gespeichert werden'));
    }
}

export const deleteUser = (angebotId) => async (dispatch) => {
    dispatch(fetchGeneralCollectionStart());
    try {
        deleteUserService(angebotId).then(() => {
            dispatch(showSuccessMessage('Angebot wurde erfolgreich gelöscht'));

        }

        )

    } catch (error) {
        dispatch(fetchGeneralCollectionFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gelöscht werden'));
    }
}