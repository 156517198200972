import React, { useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { initialState, formReducer } from '../helpers.ts';
import AngebotForm from './AngebotForm.tsx';
import '../components/publish-box.scss';

import { Button } from 'basic-components';

import { convertData } from './import.js';

import { useSelector, useDispatch } from 'react-redux';
import { fetchAngebot, updateAngebot, deleteAngebot, createAngebot } from '../../../features/angebote/angeboteThunk.js';
import { resetAngebotAction } from '../../../features/angebote/angeboteSlice.js';

function Angebot(props) {
    const { isEditPage, isImportPage } = props;
    const navigate = useNavigate();
    let { id } = useParams();

    const reduxDispatch = useDispatch();

    useEffect(() => {
        if (!isEditPage) {            
            reduxDispatch(resetAngebotAction());
        } else {
            reduxDispatch(fetchAngebot(id));
            console.log(id)
        }
    }, []);



    const data = useSelector((state) => state.angebote.tmpAngebot);
    
    useEffect(() => {
        if (isEditPage) {
            updateFormState(data);
        }
    }, [isImportPage, id, data]);
    



    const [state, dispatch] = useReducer(formReducer, initialState);

    const updateFormState = itemData => {
        if (!itemData) {
            return;
        }
        const fields = [
            'name',
            'kurzbeschreibung',
            'text',
            'link',
            'tags',
            'region',
            'termine',
            'preis',
            'headerbild',
            'wp_id',
            'organisator',
            'headerbild',
            'id',
            'status',
        ];

        fields.forEach(field => {
            if (field === 'termine') {
                dispatch({
                    type: 'UPDATE_FIELD',
                    field: field,
                    value: JSON.parse(itemData[field])
                        ? JSON.parse(itemData[field])
                        : [],
                });
                return;
            } else {
                dispatch({
                    type: 'UPDATE_FIELD',
                    field: field,
                    value: itemData[field],
                });
            }
        });
    };



    const handleChange = e => {
        dispatch({
            type: 'UPDATE_FIELD',
            field: e.target.name,
            value: e.target.value,
        });
    };

    const submit = e => {
        e.preventDefault();
        if (isEditPage) {
            reduxDispatch(updateAngebot(id, state))
        } else {
            reduxDispatch(createAngebot(state)).then((res) => {
                console.log(res)
                navigate('/angebote/bearbeiten/' + res.id)
            }
            ).catch((err) => {
                console.log(err)
            })
                      //  navigate('/angebote/bearbeiten/' + state.id)

        }
    };

    const handleUpload = state => {
        reduxDispatch(updateAngebot(id, state))
    }

    const publish = () => {
        if (state.status === 'publish') {
            handleUpload({ status: 'draft' });
        } else {
            handleUpload({ status: 'publish' });
        }
    };

    const handleDelete = (state) => {
        reduxDispatch(deleteAngebot(state.id));
        navigate('/angebote');
    }


    return (
        <div className={'base-container'}>
            <AngebotForm
                handleSubmit={submit}
                handleChange={handleChange}
                handleUpload={handleUpload}
                state={state}
                isEditPage={isEditPage}
                dispatch={dispatch}
                handleDelete={handleDelete}
            />

            {isEditPage && (
                <div className='publish-box'>
                    <Button onClick={() => publish()}>
                        {state.status === 'publish'
                            ? 'auf Entwurf setzen'
                            : 'Veröffentlichen'}
                    </Button>
                    {state.wp_id && (
                        <a
                            className='button button-link'
                            href={`${process.env.REACT_APP_BIKEGENOSS_URL}/?p=${state.wp_id}`}
                            target='_blank'
                        >
                            auf bikegenoss.ch anschauen
                        </a>
                    )}
                </div>
            )}
        </div>
    );
}

Angebot.defaultProps = {
    isEditPage: false,
};

export default Angebot;
