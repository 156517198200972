import client from '../../../helpers/directusClient';

import {
    readMe
} from '@directus/sdk';

const login = async (email, password) => {
    try {
        const response = await client.login(email, password);



        return response;
    } catch (error) {
        return Promise.reject(error || 'An error occurred during login');
    }
};

const fetchCurrentUser = async () => {
    try {
        const response = await client.request(readMe({ fields: '*,role.*' }));
        return response;
    } catch (error) {
        console.log(error);
    }
};

const refresh = async () => {
    try {
        const response = await client.refresh('cookie');
        return response;
    } catch (error) {
        console.log(error);
        console.log(error.response.status)

    }
};

const logout = async () => {
    try {
        const response = await client.logout('cookie');
        return response;
    } catch (error) {
        console.log(error);
    }
};

export default {
    login,
    refresh,
    logout,
    fetchCurrentUser
};
