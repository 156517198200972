import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
    isAuthenticated: boolean;
    user: any | null;
    error: string | null;
    loading: boolean;
}

const initialState: AuthState = {
    isAuthenticated: false,
    user: null,
    error: null,
    loading: true, // Starts as true, indicating that we're checking auth status
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = !!action.payload;
            state.loading = false;
        },
        setUser: (state, action) => {
            state.user = action.payload;
            state.error = null;
            state.loading = false; 
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const { setUser, setError, setLoading, setIsAuthenticated } = authSlice.actions;

export default authSlice.reducer;
