import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, LoginPayload } from '../../features/auth/authThunks.ts';

import { RootState, AppDispatch } from '../../store.ts';
import { useNavigate, Link } from 'react-router-dom';

import { Button, Input } from 'basic-components';

import './login-form.scss';

const Login: React.FC = () => {
    const [credentials, setCredentials] = useState<LoginPayload>({
        email: '',
        password: '',
    });
    const dispatch = useDispatch<AppDispatch>();
    const authError = useSelector((state: RootState) => state.auth.error);
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(loginUser(credentials))
            .then(response => {
                if (response.meta.requestStatus === 'fulfilled') {
                    navigate('/angebote');
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    return (
        <div className='login-form'>
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
                <Input
                    type='email'
                    name='email'
                    value={credentials.email}
                    onChange={handleChange}
                    placeholder='Email'
                />
                <Input
                    type='password'
                    name='password'
                    value={credentials.password}
                    onChange={handleChange}
                    placeholder='Passwort'
                />
                <Button type='submit'>Login</Button>
            </form>
            <Link
                to={'/password-reset'}
                className='forgot-password'
            >
                Passwort vergessen
            </Link>
            {authError && <p className='error-message'>{authError}</p>}
        </div>
    );
};

export default Login;
