import React, { useState } from 'react'
import { Input } from 'basic-components'


export default function ContentHubSearch(props) {

    const { dispatch } = props;
    const [query, setQuery] = useState('');
    const [response, setResponse] = useState();

    const searchInContentHub = async (query) => {
        try {
            const CONTENT_HUB_URL = `https://meta.et4.de/rest.ashx/search/?experience=bikegenoss&licensekey=BG67LUG82FHT3&type=Tour&template=ET2014A.json&q=title:${query}`;
            const response = await fetch(`${CONTENT_HUB_URL}`);
            const data = await response.json();
            setResponse(data);
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
        }
    }



    const handleChange = (e) => {
        setQuery(e.target.value);
    }

    const getTitles = (result) => {
        return result.items.map((item) => {
            return item.title;
        })
    }

    const setContentHubId = (id) => {
        dispatch({
            type: 'UPDATE_FIELD',
            field: 'content_hub_id',
            value: id,
        });
    }


    return (
        <div>
            <div>
                <Input
                    type='text'
                    name='content_hub_id'
                    value={query}
                    onChange={handleChange}
                    placeholder='Content Hub ID'
                    label='Content Hub ID'
                />
                <button onClick={() => searchInContentHub(query)}>Suchen</button>

            </div>

            {response && response.items && (
                <div>
                    {response.items.map((item, index) => {
                        return <p onClick={() => setContentHubId(item.id)} key={index}>{item.title}</p>
                    })}
                </div>
            )}

        </div>
    )
}
