import React, { useEffect } from 'react';
import LoginForm from '../../boilerplate/login/LoginForm.tsx';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/auth/authThunks.ts';
import './login.scss';

const LoginPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const forceLogout = async () => {
            try {
                await dispatch(logout());
                localStorage.clear();
            } catch (error) {
                console.error('Logout failed', error);
            }
        };

        forceLogout();
    }, [dispatch]);

    return (
        <div className='login-page'>
            <div className='login-page__container'>
                <LoginForm />
            </div>
        </div>
    );
};

export default LoginPage;
