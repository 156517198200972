import {
    fetchAngeboteStart,
    fetchAngeboteSuccess,
    fetchAngeboteFailure,
    fetchAngebotSuccess,


} from './angeboteSlice';
import {
    fetchAngeboteService,
    fetchAngebotService,
    updateAngebotService,
    deleteAngebotService,
    postRequestWithId,
    createAngebotService
} from './angeboteService';
import { showErrorMessage, showSuccessMessage } from '../message/messageThunk';

export const fetchAngebote = () => async (dispatch) => {
    dispatch(fetchAngeboteStart());

    try {
        const data = await fetchAngeboteService();
        dispatch(fetchAngeboteSuccess(data));
    } catch (error) {
        dispatch(fetchAngeboteFailure(error.message));
    }
};

export const fetchAngebot = (angebotId) => async (dispatch) => {
    dispatch(fetchAngeboteStart());

    try {
        const data = await fetchAngebotService(angebotId);
        dispatch(fetchAngebotSuccess(data));
    } catch (error) {
        dispatch(fetchAngeboteFailure(error.message));
    }
};

export const updateAngebot = (angebotId, angebot) => async (dispatch) => {
    dispatch(fetchAngeboteStart());
    try {
        const response = await updateAngebotService(angebotId, angebot);
        dispatch(showSuccessMessage('Angebot wurde erfolgreich gespeichert'));
        try {
            const wpResponse = await postRequestWithId(angebotId);
            dispatch(fetchAngebotSuccess(wpResponse));

        } catch (error) {
            console.error('Error making POST request:', error);

        }
    } catch (error) {
        dispatch(fetchAngeboteFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gespeichert werden'));
    }
}

export const createAngebot = (angebot) => async (dispatch) => {
    dispatch(fetchAngeboteStart());
    try {
        const response = await createAngebotService(angebot);
        dispatch(fetchAngebotSuccess(response));
        dispatch(showSuccessMessage('Angebot wurde erfolgreich gespeichert'));
        return response;
    } catch (error) {
        dispatch(fetchAngeboteFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gespeichert werden'));
    }
}

export const deleteAngebot = (angebotId) => async (dispatch) => {
    dispatch(fetchAngeboteStart());
    try {
        deleteAngebotService(angebotId).then(() => {
            dispatch(fetchAngebote()).then((data) => {
                dispatch(showSuccessMessage('Angebot wurde erfolgreich gelöscht'));

            })
        }

        )

    } catch (error) {
        dispatch(fetchAngeboteFailure(error.message));
        dispatch(showErrorMessage('Angebot konnte nicht gelöscht werden'));
    }
}