import client from '../../helpers/directusClient';
import {
    readItems,
    readItem,
    updateItem,
    deleteItem,
    createItem,
    readUsers,
    readUser,
    createUser,
    deleteUser,
    updateUser
} from '@directus/sdk';

export const fetchGeneralCollectionService = async (collection) => {
    try {
        const response = await client.request(
            readItems(collection),
        );

        return response;
    } catch (error) {
        console.error(`Error fetching item with ID:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID .`,
        );
    }
};

export const fetchGeneralItemService = async (collection, itemId) => {
    try {
        const response = await client.request(
            readItem(collection, itemId, { fields: '*,headerbild.*' }),
        );


        return response;
    } catch (error) {
        console.error(`Error fetching item with ID ${itemId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${itemId}.`,
        );
    }
};

export const updateGeneralItemService = async (collection, angebotId, angebot) => {
    try {
        const response = await client.request(
            updateItem(collection, angebotId, angebot),
        );
        return response;
    } catch (error) {
        console.error(`Error fetching item with ID ${angebotId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${angebotId}.`,
        );
    }
}

export const createGeneralItemService = async (collection, angebot) => {
    try {
        const response = await client.request(
            createItem(collection, angebot),
        );
        return response;
    } catch (error) {
        console.error(`Error fetching item with ID`, error);
        throw new Error(
            `An error occurred while fetching the item with ID`,
        );
    }
}

export const deleteGeneralItemService = async (collection, angebotId) => {
    try {
        const response = await client.request(
            deleteItem(collection, angebotId),
        );
    } catch (error) {
        console.error(`Error fetching item with ID ${angebotId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${angebotId}.`,
        );
    }
}

export const fetchUsersService = async () => {
    try {
        const response = await client.request(
            readUsers({
                fields: ['*', 'organisator.*'],
            }),
        );

        return response;
    } catch (error) {
        console.error(`Error fetching item with ID:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID .`,
        );
    }
};

export const fetchSingleUserService = async (itemId) => {
    try {
        const response = await client.request(
            readUser(itemId),
        );


        return response;
    } catch (error) {
        console.error(`Error fetching item with ID ${itemId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${itemId}.`,
        );
    }
};


export const updateUserService = async (angebotId, angebot) => {
    try {
        const response = await client.request(
            updateUser(angebotId, angebot),
        );
        return response;
    } catch (error) {
        console.error(`Error fetching item with ID ${angebotId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${angebotId}.`,
        );
    }
}

export const createUserService = async (data) => {
    try {

        const response = await client.request(
            createUser(data),
        );
        return response;
    } catch (error) {
        console.error(`Error fetching item with ID`, error);
        throw new Error(
            `An error occurred while fetching the item with ID`,
        );
    }
}

export const deleteUserService = async (angebotId) => {
    try {
        const response = await client.request(
            deleteUser(angebotId),
        );
    } catch (error) {
        console.error(`Error fetching item with ID ${angebotId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${angebotId}.`,
        );
    }
}