import React from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';

function BasicTable(props) {
    const { data, columns } = props;
    const navigate = useNavigate();

    const handleRowClick = row => {
        navigate(`bearbeiten/${row.id}`);
    };

    return (
        <DataTable
            columns={columns}
            data={data}
            highlightOnHover
            keyField='id'
            defaultSortField='id'
            defaultSortDesc
            responsive
            onRowClicked={handleRowClick}
        />
    );
}

export { BasicTable };
