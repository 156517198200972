import React, { useEffect, useState } from 'react';
import Termine from '../components/Termine.js';
import HeaderBild from '../components/HeaderBild.js';
import { useDispatch } from 'react-redux';

import client from '../../../helpers/directusClient.js';
import {
    readItems,
    readRoles

} from '@directus/sdk';


import {
    Input,
    Textarea,
    SelectInput,
    CheckboxGroup,
    ButtonGroup, Button
} from 'basic-components';

const TourForm = (props) => {
    const [options, setOptions] = useState([]);
    const {
        state,
        handleSubmit,
        handleChange,
        isEditPage,
        dispatch,
        handleUpload,
        handleDelete,

    } = props;


    const getOrganisatoren = async () => {
        try {
            const response = await client.request(
                readItems('organisatoren'),
            );

            return response;
        } catch (error) {
            console.error(`Error fetching item with ID:`, error);
            throw new Error(
                `An error occurred while fetching the item with ID .`,
            );
        }
        
    }

    const getRoles = async () => {
        try {
            const response = await client.request(
                readRoles(),
            );

            return response;
        } catch (error) {
            console.error(`Error fetching item with ID:`, error);
            throw new Error(
                `An error occurred while fetching the item with ID .`,
            );
        }
        
    }

    useEffect(() => {
        getOrganisatoren().then((response) => {
            const options = response.map((item) => {
                return {
                    text: item.name,
                    value: item.id,
                };
            });
            options.unshift({ text: 'Organisator auswählen', value: '' });
            setOptions(options);
        });
        getRoles().then((response) => {
            const tmpRole = response.filter((role) => role.name === 'Basic');
            dispatch({
                type: 'UPDATE_FIELD',
                field: 'role',
                value: tmpRole[0].id,
            });
        });
    }, []);



    return (
        <form onSubmit={handleSubmit}>
            <Input
                type='text'
                name='first_name'
                value={state.first_name}
                onChange={handleChange}
                placeholder='Vorname'
                label='Vorname'
            />
      <Input
                type='text'
                name='last_name'
                value={state.last_name}
                onChange={handleChange}
                placeholder='Nachname'
                label='Nachname'
            />
            <Input
                type='text'
                name='email'
                value={state.email}
                onChange={handleChange}
                placeholder='Email'
                label='Email'
            />
                        <Input
                type='text'
                name='password'
                value={state.password}
                onChange={handleChange}
                placeholder='Passwort'
                label='Passwort'
            />
                    <SelectInput
                options={options}
                label={'Organisator'}
                onChange={handleChange}
                name={'organisator'}
                value={state.organisator}
            />
            <ButtonGroup
                deleteItem={() => handleDelete(state)}
                isEditPage={isEditPage}
            />
        </form>
    );
};

export default TourForm;
