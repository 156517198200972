// index.tsx
import React from 'react';
import { Provider } from 'react-redux';
import { store } from './helpers/store.ts';

import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import { QueryClient, QueryClientProvider } from 'react-query';

import ErrorDisplay from './boilerplate/message/ErrorDisplay.js';

const container = document.getElementById('root');
const root = createRoot(container); // Create a root.
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
      <ErrorDisplay />
    </Provider>
  </QueryClientProvider>,
);
