import client from '../../helpers/directusClient';
import {
    readItems,
    readItem,
    updateItem,
    deleteItem,
    createItem,
} from '@directus/sdk';

export const fetchAngeboteService = async () => {
    try {
        const response = await client.request(
            readItems('angebote', { fields: '*, organisator.*' }),
        );


        return response;
    } catch (error) {
        console.error(`Error fetching item with ID:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID .`,
        );
    }
};


export const fetchAngebotService = async (angebotId) => {
    try {
        const response = await client.request(
            readItem('angebote', angebotId, { fields: '*,headerbild.*' }),
        );


        return response;
    } catch (error) {
        console.error(`Error fetching item with ID ${angebotId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${angebotId}.`,
        );
    }
};

export const updateAngebotService = async (angebotId, angebot) => {
    try {
        const response = await client.request(
            updateItem('angebote', angebotId, angebot),
        );
        return response;
    } catch (error) {
        console.error(`Error fetching item with ID ${angebotId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${angebotId}.`,
        );
    }
}

export const createAngebotService = async (angebot) => {
    try {
        const response = await client.request(
            createItem('angebote', angebot),
        );
        return response;
    } catch (error) {
        console.error(`Error fetching item with ID`, error);
        throw new Error(
            `An error occurred while fetching the item with ID`,
        );
    }
}

export const deleteAngebotService = async (angebotId) => {
    try {
        const response = await client.request(
            deleteItem('angebote', angebotId),
        );
    } catch (error) {
        console.error(`Error fetching item with ID ${angebotId}:`, error);
        throw new Error(
            `An error occurred while fetching the item with ID ${angebotId}.`,
        );
    }
}

export const postRequestWithId = async (id) => {
    const body = { id };
    const url = `${process.env.REACT_APP_BIKEGENOSS_URL}/wp-json/bikegenoss-app/v2/angebot/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error making POST request:', error);
        throw error;
    }
}
