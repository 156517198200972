const formReducer = (state, action) => {
	switch (action.type) {
		case 'UPDATE_FIELD':
			return {
				...state,
				[action.field]: action.value,
			};
		case 'UPDATE_TERMIN':
			let updateTerminState = { ...state };
			const termin = {
				...updateTerminState.termine[action.item],
				[action.key]: action.value,
			};
			updateTerminState.termine[action.item] = termin;
			return updateTerminState;

		case 'REMOVE_TERMIN':
			let removeTerminState = { ...state };
			removeTerminState.termine = removeTerminState.termine.slice(
				0,
				action.length - 1,
			);
			return removeTerminState;

		case 'ADD_TERMIN':
			let addTerminState = { ...state };
			addTerminState.termine[action.length] = {
				startdatum: '',
				enddatum: '',
				beschreibung: '',
				ort: '',
			};
			return addTerminState;
		case 'RESET_FORM':
			console.log(initialState)
			return initialState;
		default:
			return state;
	}
};

const initialState = {
	name: '',
	text: '',
	link: '',
	kurzbeschreibung: '',
	termine: [{ startdatum: '', enddatum: '', beschreibung: '', ort: '' }],
	tags: '',
	headerbild: null,
};

export { formReducer, initialState };
