
import { DateField, Input } from 'basic-components';

const Termine = (props) => {

    const { dispatch, termine } = props;



    const addTermin = () => {
        dispatch({
            type: 'ADD_TERMIN',
            length: termine?.length
        })
    }



    const removeTermin = () => {
        dispatch({
            type: 'REMOVE_TERMIN',
            length: termine?.length
        })
    }




    return (
        <div className={'termine-container input-wrapper '}>
            <h3>Termine</h3>

            {termine ?
                termine.map((item, index) => {
                    return (
                        <div className="termin-wrapper" key={index}>
                            <div className='columns-2'>
                                <DateField
                                    label="Startdatum"
                                    value={termine[index].startdatum}
                                    onChange={(e) =>
                                        dispatch({
                                            type: 'UPDATE_TERMIN',
                                            item: index,
                                            value: e,
                                            key: 'startdatum',
                                        })}
                                    className="input-wrapper"
                                />
                                <DateField
                                    label="Enddatum"
                                    value={termine[index].enddatum}
                                    onChange={(e) =>
                                        dispatch({
                                            type: 'UPDATE_TERMIN',
                                            item: index,
                                            value: e,
                                            key: 'enddatum',
                                        })}
                                />
                            </div>
                            <div className='columns-2'>
                                <Input
                                    value={termine[index].ort}
                                    onChange={(e) =>
                                        dispatch({
                                            type: 'UPDATE_TERMIN',
                                            item: index,
                                            value: e.target.value,
                                            key: 'ort',
                                        })}
                                    placeholder="Ort"
                                    label="Ort" />
                                <Input
                                    value={termine[index].beschreibung}
                                    onChange={(e) =>
                                        dispatch({
                                            type: 'UPDATE_TERMIN',
                                            item: index,
                                            value: e.target.value,
                                            key: 'beschreibung',
                                        })}
                                    placeholder="Beschreibung"
                                    label="Beschreibung" />
                            </div>

                        </div>

                    );

                })
                : null
            }

            <div className="controls">
                <div onClick={removeTermin}>
                    <img className={'add-button'} src={process.env.PUBLIC_URL + '/assets/remove.svg'} alt="arrow-filter" />
                </div>
                <div onClick={addTermin}>
                    <img className={'add-button'} src={process.env.PUBLIC_URL + '/assets/plus.svg'} alt="arrow-filter" />
                </div>

            </div>


        </div>);
};


export default Termine;


