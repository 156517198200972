// store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice.ts';
import angeboteReducer from '../features/angebote/angeboteSlice.js';
import messageReducer from '../features/message/messageSlice.js';
import tourenReducer from '../features/touren/tourenSlice.js';
import generalCollectionReducer from '../features/general-collection/generalCollectionSlice.js';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        angebote: angeboteReducer,
        message: messageReducer,
        touren: tourenReducer,
        generalCollection: generalCollectionReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
